<template>
    <div class="news" :title="data.title">
        <h1>{{ data.title }}</h1>
        <div class="info">
            <div class="left">
                <span class="origin">来源：{{ data.origin }}</span>
                <span>时间：{{ data.time }}</span>
            </div>
            <div class="right">
                <span>浏览：{{ data.count }}次</span>
            </div>
        </div> 
    </div>
</template>

<script>
    export default {
        name: 'news',
        props: {
            data: {
                type: Object,
                default () {
                    return {
                        title: '',
                        origin: '',
                        time: '',
                        count: ''
                    }
                }
            }
        }
    }
</script>

<style lang="less" scoped>
    .news {
        padding: 10px;
        background-color: #FFF;
        cursor: pointer;
        h1 {
            margin-bottom: 14px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #999;
            font-size: 10px;
            .left {
                .origin {
                    margin-right: 10px;
                }
            }
        }
    }
</style>