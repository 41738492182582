<template>
    <fhiry-page
        class="industry"
        :banners="banners"
    >
        <div class="warpper">
            <div class="inner-warppaer">
                <div class="left">
                    <news
                        class="news-item"
                        v-for="(newsItem, i) in newsListCache"
                        :key="i"
                        :data="newsItem"
                        :class="{'active': index == ((pager.currentPage - 1) * pager.pageSize) + i}"
                        @click.native="index = ((pager.currentPage - 1) * pager.pageSize) + i"
                    />
                    <div class="pager">
                        <el-pagination
                            small
                            background
                            layout="prev, pager, next"
                            :total="newsList.length"
                            :pagerCount="pager.pagerCount"
                            :page-size="pager.pageSize"
                            :current-page="pager.currentPage"
                            @current-change="currentChange"
                        >
                        </el-pagination>
                    </div>
                </div>
                <div class="right">
                    <el-scrollbar style="height: 100%">
                        <div class="right-content">
                            <news-detail
                                :newsInfo="newsList[index]"
                            />
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
    </fhiry-page>
</template>

<script>
    // 行业动态
    // 组件
    import FhiryPage from '@/components/FhiryPage';
    import News from './News';
    import NewsDetail from './NewsDetail';

    // api
    import { getIndustry } from '@/api/request';

    export default {
        name: 'industry',
        created () {
            this.getIndustryAjax();
        },
        data () {
            return {
                banners: [],
                newsList: [],
                index: 0,
                pager: {
                    pageSize: 8,
                    pagerCount: 5,
                    currentPage: 1
                }
            }
        },
        computed: {
            newsListCache () {
                let start = (this.pager.currentPage - 1) * 8;
                return this.newsList.slice().slice(start, start + this.pager.pageSize);
            }
        },
        methods: {
            getIndustryAjax () {
                getIndustry().then(res => {
                    this.banners = res.banners;
                    this.newsList = res.newsList;
                });
            },
            currentChange (page) {
                this.pager.currentPage = page;
            }
        },
        components: {
            FhiryPage,
            News,
            NewsDetail
        }
    }
</script>

<style lang="less" scoped>
    @import '~@/assets/less/common.less';

    .industry {
        .warpper {
            padding: 20px 0;
            .inner-warppaer {
                display: flex;
                margin: 0 auto;
                width: @limit-width;    
                overflow: hidden;
                .left {
                    margin-right: 20px;
                    width: 330px;
                    .news-item {
                        margin-bottom: 14px;
                        transition: all 0.3s;
                        &:hover, &.active {
                            background-color: #f9f9f9;
                        }
                    }
                    .pager {
                        text-align: center;
                    }
                }
                .right {
                    height: 720px;
                    width: calc(~'100% - 350px');
                    background-color: #FFF;
                    overflow: auto;
                    .right-content {
                        padding: 32px 30px;
                    }
                }
            }
        }
    }
</style>

<style lang="less">
    .el-scrollbar__wrap {
        overflow-x: hidden;
    }
</style>