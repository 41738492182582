<template>
    <div class="news-detail">
        <header class="news-header">
            <h1>{{ newsInfo.title }}</h1>
            <div class="sub-name">
                <span>来源:{{ newsInfo.origin }}</span>
                <span>发布日期:{{ newsInfo.time }}</span>
                <span>浏览:{{ newsInfo.count }}次</span>
            </div>
        </header>
        <div class="content" v-html="newsInfo.content"></div>
    </div>
</template>

<script>    
    export default {
        name: 'news-detail',
        props: {
            newsInfo: {
                type: Object,
                default () {
                    return {
                        title: '',
                        origin: '',
                        time: '',
                        count: '',
                        content: ''
                    }
                }
            } 
        }
    }
</script>

<style lang="less" scoped>
    .news-detail {
        .news-header {
            margin: 0 auto;
            text-align: center;
            width: 330px;
            h1 {
                margin-bottom: 6px;
                font-weight: bold;
                font-size: 22px;
            }
            .sub-name {
                display: flex;
                justify-content: space-between;
                color: #bbb;
                font-size: 10px;
            }
        }
    }
</style>

<style lang="less">
    .news-detail {
        .content {
            font-size: 12px;
            img {
                margin: 20px 0;
                width: 100%;
            }
            p {
                color: #999;
                text-indent: 24px;
                line-height: 2;
            }
            h2 {
                margin: 10px 0;
                font-size: 16px;
                text-indent: 24px;
            }
        }
    }
</style>